﻿//Unfortunately we can't override everything in Bootstrap, by overriding
//it's dependent variables, as it doesn't use variables for everything
//For these situations, we override it manually

.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
    /*opacity: .40;*/
    &.btn-primary {
        color: #ddd;
    }
}
